<template>
  <nabvar @changeLang="changeLang"></nabvar>
  <div class="mobile-bg login-content">
    <div class="container-fluid landing login-form-content">
      <section class="">
        <div class="container">
          <div class="row">
            <div class="login-form">
              <div class="row menu-style">
                <div class="col-6 " v-for="m in cMenu" :key="m.label">
                  <div class="contact-us">
                    <a
                      class=" text-left  "
                      style="color:white; "
                      aria-current="page"
                      target="_blank"
                      :href="m.url"
                      >{{ m.label }}</a
                    >
                  </div>
                </div>
                <div class="col-6 text-right lang-style">
                  <a
                    @click="changeLang(lang)"
                    href="javascript:;"
                    v-for="lang in langs"
                    :key="lang.value"
                    :class="{
                      'lang-link': true,
                      'lang-link-active': langSelected.value == lang.value,
                      'btn-lang': langSelected.value !== lang.value,
                    }"
                  >
                    {{ lang.value }}</a
                  >
                </div>
              </div>
              <div class="login-form-box">
                <img
                  :src="require('./assets/ficopos.png')"
                  class="img-fluid logo-ficopos mb-5"
                  alt=""
                />
                <h1 class="title">{{ title }}</h1>

                <div class="row">
                  <div
                    v-for="country in countries"
                    :key="country.country"
                    class="col-6 text-center  mt-5"
                  >
                    <countries :country="country"></countries>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <img
            style="width: 120px "
            class="poweredby"
            alt=""
            :src="require('./assets/powered-by.svg')"
            height="32"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import nabvar from "./components/partials/navbar.vue";
import countries from "./components/countries.vue";

export default {
  name: "App",
  components: {
    // HelloWorld,
    nabvar,
    countries,
  },
  data() {
    return {
      menu: [
        {
          label: "CONTÁCTANOS",
          url:
            "https://grupofinanciero.ficohsa.com/ficopos/afiliar-mi-comercio/",
        },
      ],
      englishMenu: [
        {
          label: "CONTACT US",
          url:
            "https://grupofinanciero.ficohsa.com/ficopos/afiliar-mi-comercio/",
        },
      ],
      langSelected: {
        value: "es",
        text: "Español",
      },
      currentWidth: 0,
      countries: [
        {
          name: "Honduras",
          flag: "honduras.png",
          redirect: "https://hn.ficoposonline.com",
          disabled: false,
        },
        {
          name: "Nicaragua",
          flag: "nicaragua.png",
          redirect: "https://ni.ficoposonline.com",
          disabled: false,
        },
      ],
      lang: "es",
      langs: [
        {
          value: "en",
          text: "English",
        },
        {
          value: "es",
          text: "Español",
        },
      ],
    };
  },
  methods: {
    changeLang(lang) {
      this.langSelected = lang;
    },
  },
  computed: {
    cLangs() {
      return this.langs.filter((x) => x.value !== this.langSelected.value);
    },
    cMenu() {
      return this.langSelected.value == "es" ? this.menu : this.englishMenu;
    },
    title() {
      return this.langSelected.value == "es"
        ? "SELECCIONE SU PAÍS"
        : "SELECT YOUR COUNTRY";
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.currentWidth = window.innerWidth;
    });
    window.addEventListener("resize", () => {
      this.currentWidth = window.innerWidth;
    });
  },
};
</script>

<style lang="scss">
.h-100 {
  height: 100%;
}
#app {
  font-family: "Poppins", "Arial", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.full-height {
  height: 100%;
}
.menu-style {
  color: white;
  a {
    text-decoration: none;
  }
  a:hover {
    color: white;
  }
  .contact-us {
    transition: all 0.4s;
    cursor: pointer;
    text-align: left;
  }
  .contact-us:hover {
    transform: translateY(2px);
  }
}
.login-content {
  display: block;
  width: 100%;
  height: 100%;
  height: 100vh;
  position: relative;
  min-height: 560px;
  background-position: 50%;
  background-size: 600px 538px;
  .login-form-content {
    padding: 20px;
    height: 100%;
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.landing {
  background-image: url("./assets/login-bg.svg");
  background-color: #12447f;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: auto 100%;
  background-attachment: fixed;
}
.logo-ficopos {
  margin: auto;
  width: 166px;
}
.title {
  font-size: 1.7rem;
  color: black;
}
.subtitle {
  font-size: 1.5rem;
  color: black;
  font-weight: 100 !important;
}
.poweredby {
  position: absolute;
  bottom: 20px;
  left: 20px;
  height: 40px;
  opacity: 0.8;
  height: 32px;
  color: #fff;
  text-decoration: none;
  font-weight: bolder;
}
body {
  min-height: 100%;
}
.centerdiv {
  padding: 20px;
  height: 100%;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-form {
  width: 100%;
  max-width: 350px;

  .login-form-box {
    flex: 1;
    width: 100%;
    max-width: 330px;
    background-color: #fff;
    padding: 26px 20px;
    border-radius: 10px;
    box-shadow: 0 3px 20px rgb(0 0 0 / 10%);
    border: 1px solid hsla(0, 0%, 93.3%, 0.5);
    position: relative;
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media (min-width: 500px) {
  .title {
    font-size: 2rem;
    color: black;
  }
}
@media (max-width: 574px) {
  .mobile-bg {
    background-image: url(/img/login-bg.11226a27.svg);
    background-color: #12447f;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: auto 100%;
    background-attachment: fixed;
  }
}
@media (max-width: 5000) {
  .mobile-bg {
    background-image: url(/img/login-bg.1126a27.sv);
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: auto 100%;
    background-attachment: fixed;
  }
}
.lang-link {
  display: inline-block;
  padding: 9px 8px 7px;
  border-radius: 6px;
  color: white;
  margin-left: 4px;
  text-decoration: none;
  line-height: 0.8em;
  transition: all 0.4s;
}
.lang-link:hover {
  transform: translateY(2px);
}
.lang-link-active {
  background-color: white;
  color: black;
}
.lang-link-active:hover {
  color: black !important;
}
.lang-style {
  text-transform: uppercase;
  margin-bottom: 5px;
  text-align: right;
}
</style>
