<template>
  <a
    class="wrap"
    @click="openModal()"
    :href="!country.disabled ? country.redirect : 'javascript:;'"
    :target="!country.disabled ? '_blank' : ''"
  >
    <div>
      <div :style="{ opacity: country.disabled ? '0.34' : '1' }">
        <img
          :src="require('../assets/' + country.flag)"
          class="img-fluid mx-auto img-country mb-2 flag"
          :style="{
            minHeight: $parent.$data.currentWidth < 321 ? '55px' : '75px',
          }"
          alt=""
        />
        <span
          class="title-country"
          :style="{
            fontSize: $parent.$data.currentWidth < 321 ? '18px' : '22px',
          }"
          >{{ country.name }}</span
        >
      </div>
    </div>
  </a>
</template>

<script>
import tingle from "tingle.js";

var modal = new tingle.modal({
  footer: false,
  stickyFooter: false,
  closeMethods: ["overlay", "button", "escape"],
  closeLabel: "Close",
  cssClass: ["custom-class-1", "custom-class-2"],
  onOpen: function() {},
  onClose: function() {},
});
export default {
  name: "nabvar",
  props: {
    country: Object,
  },
  data() {
    return {
      text: {
        español: "Disponible próximamente",
        english: "Coming soon",
      },
    };
  },
  methods: {
    openModal() {
      if (!this.country.disabled) return;
      let message =
        this.$parent.$data.langSelected.value == "es"
          ? this.text.español
          : this.text.english;
      modal.setContent("<span class='coming-soon'>" + message + "</span>");
      modal.open();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.tingle-modal {
  background: rgba(0, 0, 0, 0.4) !important;
  display: flex !important;
}
.tingle-modal:before {
  backdrop-filter: blur(4px) !important;
}
.tingle-modal-box {
  width: auto;
  border-radius: 0.5rem !important;
  text-align: center;
  max-width: 500px;
}

h1 {
  font-size: 1.3rem !important;
}
.coming-soon {
  font-size: 2rem;
}
</style>

<style lang="scss" scoped>
.title-country {
  font-size: 22px;
  text-decoration: none;
}
a:hover {
  color: black;
}
.wrap {
  text-decoration: none !important;
  color: black !important;
}
.flag:hover {
  transform: translateY(2px);
}
.flag {
  transition: all 0.4s;
}
.disabled-flag {
  opacity: 35%;
}
.img-country {
  width: 100%;
  border-radius: 10px;
  min-height: 80px;
}
.card-country {
  max-width: 168px;
  border-radius: 0px;
  margin: auto;
}
</style>
