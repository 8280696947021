<template>
  <nav class="navbar navbar-expand-lg menu  navbar-dark bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">
        <img
          :src="require('../../assets/Ficohsa_logo_blanco.png')"
          class="img-fluid logo"
          :class="{ 'mt-5': $parent.$data.currentWidth > 500 }"
          alt=""
        />
      </a>
    </div>
  </nav>
</template>

<script>
export default {
  name: "nabvar",
  props: {
    msg: String,
  },
  data() {
    return {
      menu: [
        {
          label: "CONTÁCTANOS",
          url:
            "https://grupofinanciero.ficohsa.com/ficopos/afiliar-mi-comercio/",
        },
      ],
      englishMenu: [
        {
          label: "CONTACT US",
          url:
            "https://grupofinanciero.ficohsa.com/ficopos/afiliar-mi-comercio/",
        },
      ],
      langs: [
        {
          value: "es",
          text: "Español",
        },
        {
          value: "en",
          text: "English",
        },
      ],
      langSelected: {
        value: "es",
        text: "Español",
      },
    };
  },
  computed: {
    cLangs() {
      return this.langs.filter((x) => x.value !== this.langSelected.value);
    },
    cMenu() {
      return this.langSelected.value == "es" ? this.menu : this.englishMenu;
    },
  },
  methods: {
    changeLang(lang) {
      this.langSelected = lang;
      this.$emit("changeLang", lang);
    },
  },
  mounted() {
    console.log(this.$parent.$data.currentWidth);
  },
};
</script>

<style lang="scss" scoped>
.menu {
  height: 50px;
  padding: 5px;
}
.logo {
  width: 200px;
  float: left;
}

a:hover {
}
.no-decoration:hover {
  text-decoration: black !important;
}
.menu-left {
  float: right;
  font-size: 20px;
  color: white;
  top: 15px;
  text-decoration: none;
  position: relative;
  cursor: pointer;
}
.separator {
  color: white;
  top: 6px;
  position: relative;
}
.lang-link {
  display: inline-block;
  padding: 9px 8px 7px;
  border-radius: 6px;
  color: white;
  margin-left: 4px;
  text-decoration: none;
  line-height: 0.8em;
  transition: all 0.4s;
}
.lang-link-active {
  background-color: white;
  color: black;
}
.lang-style {
  padding-top: 5px;
  text-transform: uppercase;
  text-align: left;
}
.nav-link {
  transition: all 0.4s;
}
.nav-link:hover {
  transform: translateY(2px);
}
.btn-lang {
  transition: all 0.4s;
}
.btn-lang:hover {
  transform: translateY(2px);
}
.navbar {
  background-color: rgb(18, 68, 127) !important;
  background-image: url(/img/login-bg.11226a27.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: auto 100%;
  background-attachment: fixed;
  z-index: 1000;
  width: 100%;
  position: fixed;
}
</style>
